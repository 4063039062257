.bg-exampla {
    background-color: #ececec;
}

.bg-menu {
    background-color: #106ce6;
}

.bg-submenu {
    background-color: #838eb0;
}

.bg-li {
    background-color: #505d82;
}

.bg-footer {
    background-color: #106de8;
}

.bg-fila {
    background-color: #cfdeff;
}

.bg-box1 {
    background-color: #cfdeff;
}

.bg-box2 {
    background-color: #f0f2f4;
}

.bg-box3 {
    background-color: #ececec;
}

.color-text {
    color: #0961ae;
}

.h300 {
    height: 320px;
}

.border-inf {
    border-bottom: 8px solid #283988;
    width: 16%;
}

.bg-exampla1 {
    background-color: #545e80;
}
.bg-exampla2 {
    background-color: #858dad;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: normal;
}

.img-background {
    /* Ubicación de la imagen */

    background-image: url('../image/login_img.jpg');

    /* Para que la imagen de fondo no se repita */

    background-repeat: no-repeat;

    /* La imagen de fondo se reescala automáticamente con el cambio del ancho de ventana del navegador */

    background-size: cover;

    /* Se muestra un color de fondo mientras se está cargando la imagen
    
    de fondo o si hay problemas para cargarla */

    /* background-color: 66999; */
}

.font-responsive {
    font-size: calc(0.7rem + 0.390625vw);
}

@media (min-width: 481px) {
}
