.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    /* height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3); */

    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /* background: rgba(255, 255, 255, 0.3); */
    background: url('assets/image/logo-exempla-blanco.svg');
}

.site-layout {
    height: 100%;
}

.site-layout .ant-layout {
    min-height: 100vh;
}

.site-layout .ant-layout-header {
    background: #106ce6;
    color: #fff;
    padding: 0px 40px 0px 40px;
}

.site-layout .avatar-menu {
    float: right;
}

.site-layout .avatar-menu ul li {
    list-style: none;
}

.site-layout .ant-layout-content {
    /* background: #fff;
    margin: 24px 16px; */
    /* padding: 24px; */
    margin: 0 16px;
}

.site-layout .layout-content {
    background: #f0f2f5;
    /* padding: 24px; */
    height: calc(100% - 70px);
}

.site-layout .ant-layout-sider {
    background: #505d82;
}

.site-layout .ant-menu.ant-menu-dark {
    color: #fff;
    background: #505d82;
}

.site-layout .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #838eb0;
}

.site-layout .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #6474a1;
}

.site-layout .ant-layout-footer {
    background: #106de8;
    padding: 0px;

    color: #ffffff;
    font-size: 0.8rem;
}

.site-layout .ant-menu-inline .ant-menu-submenu {
    border-bottom: 1px solid;
}

.site-layout .link-footer {
    color: white;
}

.question-layout .ant-tabs-top > .ant-tabs-nav::before {
    border-color: #f0f0f0;
}

.question-layout .ant-tabs > .ant-tabs-nav {
    margin-bottom: 0;
}

.question-layout .ant-tabs-content-holder {
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-top: transparent;
}

.login .row {
    align-items: center;
}

.ant-comment-inner {
    padding: 5px !important;
}

.atencion-clientes {
    margin: 10px 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #1890ff !important;
}
